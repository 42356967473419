import { useMemo, useEffect } from "react";

const ScrollToHashElement = ({ id }) => {
  const hashElement = useMemo(() => {
    let returneEl = null;
    if (id) {
      returneEl = document.getElementById(id);
    } else {
      returneEl = null;
    }

    return returneEl;
  }, [id]);

  useEffect(() => {
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: "smooth",
        // block: "end",
        inline: "nearest",
      });
    }
  }, [hashElement]);

  return null;
};

export default ScrollToHashElement;
