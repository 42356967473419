import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { AppChildrenWrapper, AppWrapper } from "./AppLayout.styled";

const AppLayout = ({ children }) => {
  return (
    <AppWrapper className="AppLayout-AppWrapper">
      <Header />
      <AppChildrenWrapper className="AppChildrenWrapper">
        {children}
      </AppChildrenWrapper>
      <Footer />
    </AppWrapper>
  );
};

export default AppLayout;
