import { useContext, useEffect, useState } from "react";
import {
  BurgerMenu,
  BurgerMenuItem,
  BurgerMenuItemsWrapper,
  BurgerMenuLogo,
  HeaderMenuWrapper,
  HeaderWrapper,
  Logo,
  MyImage,
  SocialsWrapper,
} from "./Header.styled";
import { DrawerContext } from "../reactContext/DrawerContext";
import { CustomBurgerIcon } from "./CustomBurgerIcon";
import { useMediaQuery } from "../../utils/useMediaQuery";
// import { useLocation } from "react-router-dom";
import { useScrollDirection } from "../../utils/useScrollDirection";
import logo from "../../images/Logos/logo.png";
import { Link } from "react-router-dom";
import Socials from "../socials/Socials";

const menuItems = [
  { id: "Home", label: "Home", isPage: false },
  { id: "Products", label: "Products", isPage: false },
  { id: "Brochure", label: "Brochure", isPage: false },
  { id: "News", label: "News", isPage: false },
  { id: "About-us", label: "About Us", isPage: true },
  { id: "Contact-us", label: "Contact", isPage: true },
];

const menuItems1 = [
  { id: "Home", label: "Home", isPage: false },
  { id: "Products", label: "Products", isPage: false },
  { id: "Brochure", label: "Brochure", isPage: false },
];

const menuItems2 = [
  { id: "News", label: "News", isPage: false },
  { id: "About-us", label: "About Us", isPage: true },
  { id: "Contact-us", label: "Contact", isPage: true },
];

const Header = ({ showSidebarButton = true }) => {
  const { showSidebar, toggleSidebar } = useContext(DrawerContext);
  const isSmallerThan1000 = useMediaQuery("(max-width: 1000px)");

  /*This selectedMenuItem state variable can be used to track which menu item
  is currently active, and apply specific styles or behavior to the active item.*/
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const handleMenuItemClick = (itemId) => {
    setSelectedMenuItem(itemId);
  };

  /* Close when clicked outside BurgerMenu */
  useEffect(() => {
    const handleClickOutside = (event) => {
      const burgerButton = document.getElementById("nav-icon3");
      if (
        showSidebar &&
        event.target !== burgerButton &&
        !burgerButton.contains(event.target) &&
        !event.target.closest(".BurgerMenu")
      ) {
        toggleSidebar();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSidebar, toggleSidebar]);

  /* Add class .open to the nav-icon3 and toggle the sidebar with toggleSidebar() */
  const toggleClassOnClick = () => {
    const burgerIcon = document.getElementById("nav-icon3");
    burgerIcon.addEventListener("click", () => {
      burgerIcon.classList.toggle("open");
      toggleSidebar();
    });
  };

  const isScrollingDown = useScrollDirection();

  /*HeaderShrinkOnScrollStyle*/
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderWrapper
      className="HeaderWrapper"
      isScrollingDown={isScrollingDown}
      // initial={{ y: -200 }}
      // animate={{ y: 0 }}
      // transition={{ duration: 1 }}
      isScrolled={isScrolled}
    >
      {isSmallerThan1000 ? (
        showSidebarButton && (
          //If the user is on mobile...show the burger icon...
          <>
            <Logo href="/" className="prevent-select" isScrolled={isScrolled}>
              <MyImage src={logo} isScrolled={isScrolled} />
            </Logo>
            <div>
              <CustomBurgerIcon
                className="CustomBurgerIcon"
                onClick={toggleClassOnClick}
              />
              <BurgerMenu
                className="BurgerMenu"
                id="burgerMenu"
                isOpen={showSidebar}
              >
                <BurgerMenuLogo href="/" className="prevent-select">
                  <MyImage src={logo} />
                </BurgerMenuLogo>
                <BurgerMenuItemsWrapper>
                  {menuItems.map((item) => (
                    <BurgerMenuItem
                      key={item.id}
                      onClick={toggleSidebar}
                      className="prevent-select"
                    >
                      <Link
                        to={item.isPage ? item.id : `/#${item.id}`}
                        onClick={() => handleMenuItemClick(item.id)}
                      >
                        <span
                          className={
                            selectedMenuItem === item.id ? "ActiveSection" : ""
                          }
                          style={{ fontSize: "14px" }}
                        >
                          {item.label}
                        </span>
                      </Link>
                    </BurgerMenuItem>
                  ))}
                </BurgerMenuItemsWrapper>
                <SocialsWrapper className="SocialsWrapper">
                  <Socials />
                </SocialsWrapper>
              </BurgerMenu>
            </div>
          </>
        )
      ) : (
        //else.... if user is on screen width bigger than 1000px
        // - or on desktop/laptop (NOT mobile), show the header menu..
        <HeaderMenuWrapper className="HeaderMenuWrapper prevent-select">
          {menuItems1.map((item) => (
            //for the first 3 menu items.....
            <li key={item.id}>
              <Link
                to={item.isPage ? item.id : `/#${item.id}`}
                onClick={() => handleMenuItemClick(item.id)}
              >
                <span
                  className={
                    selectedMenuItem === item.id ? "ActiveSection" : ""
                  }
                  style={{ fontSize: "14px" }}
                >
                  {item.label}
                </span>
              </Link>
            </li>
          ))}

          <Logo href="/" className="prevent-select">
            <MyImage src={logo} isScrolled={isScrolled} />
          </Logo>

          {menuItems2.map((item) => (
            //for the last 3 menu items.....
            <li key={item.id}>
              <Link
                to={item.isPage ? item.id : `/#${item.id}`}
                onClick={() => handleMenuItemClick(item.id)}
              >
                <span
                  className={
                    selectedMenuItem === item.id ? "ActiveSection" : ""
                  }
                  style={{ fontSize: "14px" }}
                >
                  {item.label}
                </span>
              </Link>
            </li>
          ))}
        </HeaderMenuWrapper>
      )}
    </HeaderWrapper>
  );
};

export default Header;
