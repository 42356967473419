import { Image } from "@chakra-ui/react";
import styled from "styled-components";
import circlesLeftSide from "../../../images/circlesLeftSide.svg";
import circlesRightSide from "../../../images/circlesRightSide.svg";

export const BrochureWrapper = styled.section`
  min-height: calc(100vh - 70px);
  /* min-height: 100%; */
  width: 100%;
  /* background-color: papayawhip; */
  background-color: var(--bg-secondary-color);
  padding: 30px 100px;

  scroll-margin-top: 70px;
  -webkit-scroll-margin-top: 70px;
  -moz-scroll-margin-top: 70px;
  -ms-scroll-margin-top: 70px;
  @media (max-width: 1000px) {
    min-height: calc(100vh - 60px);
    padding: 30px 50px;

    scroll-margin-top: 60px;
    -webkit-scroll-margin-top: 60px;
    -moz-scroll-margin-top: 60px;
    -ms-scroll-margin-top: 60px;
  }
  @media (max-width: 600px) {
    padding: 30px 30px;
  }

  /* Custom styles */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  > * + * {
    margin-top: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: url(${circlesRightSide});
    background-repeat: no-repeat;
    background-size: contain;
    height: 300px;
    width: 300px;
    z-index: 999;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3%;
    background: url(${circlesLeftSide});
    background-repeat: no-repeat;
    background-size: contain;
    height: 250px;
    width: 250px;
    z-index: 999;
  }
`;

export const BrochureContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--bg-secondary-color);
  margin-bottom: 40px;
  > * + * {
    margin-left: 40px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 20px;

    > * + * {
      margin-left: 0px;
      margin-top: 40px;
    }
  }
`;

export const LeftSideContent = styled.div`
  display: flex;
  align-self: center;
  background-color: var(--bg-secondary-color);
  /* @media (max-width: 1000px) {
    margin-top: 20px;
    text-align: center;
  } */
`;

export const BrochureImg = styled(Image)`
  height: 421px;
  width: 352px;
  border: 1px solid var(--main-color);
  position: relative;
  z-index: 9999;
  @media (max-width: 1000px) {
    height: 380px;
    width: 318px;
  }
  @media (max-width: 600px) {
    height: 283px;
    width: 237px;
  }
`;

export const RightSideContant = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: var(--bg-secondary-color);
  @media (max-width: 1000px) {
    text-align: center;

    a {
      align-self: center;
    }
  }
`;
