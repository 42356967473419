import { motion } from "framer-motion";
import styled from "styled-components";

export const HomePageWrapper = styled(motion.div)`
  position: relative;
  min-height: 100vh;
  /* margin: 0 auto; */
  /* padding: 0px 100px 30px; */
  filter: ${({ isOpen }) => (isOpen ? "blur(4px)" : "none")};
`;
