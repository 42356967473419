export const LoadingIndicator = () => (
  <div
    height="100vh"
    width="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);
