import React from "react";
import {
  ColumnsWrapper,
  FooterWrapper,
  CreditsWrapper,
  DevelopedBy,
  Rights,
  ColumnsChildWrapper,
  TitleHeading,
  MyCustomAccordion,
} from "./Footer.styled";
// import { footerSections } from "../../utils/mockData";
import parse from "html-react-parser";
import Socials from "../socials/Socials";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const CustomFooter = () => {
  const isSmallerThan1000 = useMediaQuery("(max-width: 1000px)");

  return (
    <FooterWrapper className="FooterWrapper">
      {/* <ColumnsWrapper className="DesktopFooterWrapper">
          {footerSections.map(
            ({ title, item1, item2, item3, item4, socials }) => (
              <ColumnsChildWrapper className="ColumnsChildWrapper" key={title}>
                {title && <TitleHeading as="h1">{title}</TitleHeading>}
                {item1 && <li>{parse(item1)}</li>}
                {item2 && <li>{parse(item2)}</li>}
                {item3 && <li>{parse(item3)}</li>}
                {item4 && <li>{parse(item4)}</li>}
                {socials && <Socials />}
              </ColumnsChildWrapper>
            )
          )}
        </ColumnsWrapper> */}
      <ColumnsWrapper className="DesktopFooterWrapper">
        <ColumnsChildWrapper>
          <TitleHeading as="h1">CONTACT US</TitleHeading>
          {parse(
            "<p><a href='mailto:sales@elixistrade.com'>sales@elixistrade.com</a></p>"
          )}
          {parse("<p>98 CUTTERMILL ROAD, STE466,</br>GREAT NECK NY 11021</p>")}
          {parse(
            "<p><a href='tel: +1 (239) 744-1371'>+1 (239) 744-1371</a></p>"
          )}
        </ColumnsChildWrapper>

        <ColumnsChildWrapper>
          <TitleHeading as="h1">ELIXIS TRADE CORP</TitleHeading>
          <p>
            <Link to="aqua-page">Aqua Prestige</Link>
          </p>
          <p>
            <Link to="truffle-page">Fresh Truffle</Link>
          </p>
        </ColumnsChildWrapper>

        <ColumnsChildWrapper>
          <TitleHeading as="h1">POLICIES</TitleHeading>
          {/* <Link to="/privacy-policy">{parse("<p>Privacy Policy</p>")}</Link>

          {parse("<p>About Us</p>")}
          {parse("<p>Contact</p>")} */}
          <p>
            <Link to="privacy-policy">Privacy Policy</Link>
          </p>
          <p>
            <Link to="about-us">About Us</Link>
          </p>
          <p>
            <Link to="contact-us">Contact</Link>
          </p>
        </ColumnsChildWrapper>

        <ColumnsChildWrapper>
          <TitleHeading as="h1">CONNECT WITH US</TitleHeading>
          <Socials />
        </ColumnsChildWrapper>
      </ColumnsWrapper>

      {isSmallerThan1000 && (
        <MyCustomAccordion
          defaultIndex={[3]}
          // index={[3]}
          allowMultiple
          className="MobileFooterWrapper"
        >
          <AccordionItem borderColor={"#6c6c6c"}>
            <ColumnsChildWrapper>
              <AccordionButton paddingTop="14px" paddingBottom="14px">
                <Box as="span" flex="1" textAlign="left">
                  <TitleHeading as="h2">CONTACT US</TitleHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                {parse(
                  "<p><a href='mailto:sales@elixistrade.com'>sales@elixistrade.com</a></p>"
                )}
                {parse(
                  "<p>98 CUTTERMILL ROAD, STE466,</br>GREAT NECK NY 11021</p>"
                )}
                {parse(
                  "<p><a href='tel: +1 (239) 744-1371'>+1 (239) 744-1371</a></p>"
                )}
              </AccordionPanel>
            </ColumnsChildWrapper>
          </AccordionItem>

          <AccordionItem borderColor={"#6c6c6c"}>
            <ColumnsChildWrapper>
              <AccordionButton paddingTop="14px" paddingBottom="14px">
                <Box as="span" flex="1" textAlign="left">
                  <TitleHeading as="h2">ELIXIS TRADE CORP</TitleHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <p>
                  <Link to="aqua-page">Aqua Prestige</Link>
                </p>
                <p>
                  <Link to="truffle-page">Fresh Truffle</Link>
                </p>
              </AccordionPanel>
            </ColumnsChildWrapper>
          </AccordionItem>

          <AccordionItem borderColor={"#6c6c6c"}>
            <ColumnsChildWrapper>
              <AccordionButton paddingTop="14px" paddingBottom="14px">
                <Box as="span" flex="1" textAlign="left">
                  <TitleHeading as="h2">POLICIES</TitleHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <p>
                  <Link to="privacy-policy">Privacy Policy</Link>
                </p>
                <p>
                  <Link to="about-us">About Us</Link>
                </p>
                <p>
                  <Link to="contact-us">Contact</Link>
                </p>
                {/* <Link to="/privacy-policy">
                  {parse("<p>Privacy Policy</p>")}
                </Link>
                {parse("<p>About Us</p>")}
                <Link to="/contact-us">{parse("<p>Contact</p>")}</Link> */}
              </AccordionPanel>
            </ColumnsChildWrapper>
          </AccordionItem>

          <AccordionItem borderColor={"#6c6c6c"}>
            <ColumnsChildWrapper>
              <AccordionButton paddingTop="14px" paddingBottom="14px">
                <Box as="span" flex="1" textAlign="left">
                  <TitleHeading as="h2">CONNECT WITH US</TitleHeading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Socials />
              </AccordionPanel>
            </ColumnsChildWrapper>
          </AccordionItem>
        </MyCustomAccordion>
      )}

      <CreditsWrapper>
        <Rights>© 2023 All rights reserved. ElixisTradeCorp.</Rights>
        <DevelopedBy href="https://vasilht.netlify.app/">
          Designed & Developed by VasilHT.
        </DevelopedBy>
      </CreditsWrapper>
    </FooterWrapper>
  );
};

export default CustomFooter;

//borderColor={"#6c6c6c"} ova odi na sekoj AccordionItem
