import { Link } from "react-router-dom";
import { StyledHeading } from "../../../../common/styles/Common.styled";
import {
  AquaContentWrapper,
  AquaCustomButton,
  AquaProductsWrapper,
  AquaStyledText,
} from "./AquaProducts.styled";
import parse from "html-react-parser";

export const AquaProducts = () => {
  return (
    <AquaProductsWrapper className="AquaProductsWrapper" id="Products">
      <AquaContentWrapper className="AquaContentWrapper">
        <StyledHeading>Aqua Prestige</StyledHeading>
        <AquaStyledText style={{ backgroundColor: "transparent" }}>
          {parse(
            "<p>Indulge in the extraordinary with Aqua Prestige, our distinguished collection of premium water.</p>"
          )}
          {parse(
            "<p>Crafted with meticulous care and sourced from pristine springs, Aqua Prestige embodies purity, taste, and rejuvenation.</p>"
          )}
          {parse(
            "<p>Experience hydration like never before as you delight in the refreshing qualities of Aqua Prestige.</p>"
          )}
        </AquaStyledText>
        <Link to="/aqua-page">
          <AquaCustomButton style={{ backgroundColor: "transparent" }}>
            Learn More
          </AquaCustomButton>
        </Link>
      </AquaContentWrapper>
    </AquaProductsWrapper>
  );
};
