import { Button, Checkbox, Input } from "@chakra-ui/react";
import styled from "styled-components";

export const InputButtonWrapper = styled.section.attrs((props) => ({
  isFocused: props.isFocused || false,
}))`
  display: flex;
  flex-direction: row;
  background-color: inherit;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    margin-bottom: 25px;
  }
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
  transition: border-color 0.4s ease-in-out;
  border-bottom: 3px solid
    ${(props) =>
      (props.isFocused && "var(--main-color)") ||
      "var(--line-color)"} !important;
`;

export const MyInput = styled(Input)`
  :focus {
  }
`;

export const SubmitButton = styled(Button)`
  :focus {
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: inherit;
  margin-bottom: 40px;
  @media (max-width: 1000px) {
    margin-bottom: 35px;
  }
  @media (max-width: 600px) {
    margin-bottom: 30px;
  }
`;

export const MyCheckbox = styled(Checkbox)`
  /* margin-right: 10px; */
  /* background-color: inherit; */
  /* &:checked {
    filter: hue-rotate(180deg);
  } */
`;

// export const MyLabel = styled.label``;

/* Toast... */
export const SuccessToast = styled.div`
  position: relative;
  padding: 10px;
  background-color: ${({ status }) => (status === "success" ? "green" : "red")};
  border-radius: 5px;
  h1,
  p {
    background-color: inherit;
  }
  button {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const MiniHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: inherit;
  svg {
    margin-right: 10px;
    background-color: inherit;
    height: 20px;
    width: 20px;
  }
`;
