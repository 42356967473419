import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { DrawerContext } from "../reactContext/DrawerContext";

const CustomBurgerIconWrapper = styled.div`
  position: relative;
  z-index: 999;
  background-color: ${({ showSidebar }) =>
    showSidebar ? "#202020" : "transparent"};
  /* background-color: ${({ showSidebar }) =>
    showSidebar ? "red" : "green"}; */
`;

export const CustomBurgerIcon = () => {
  const { showSidebar, toggleSidebar } = useContext(DrawerContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    toggleSidebar();
  };

  useEffect(() => {
    setIsOpen(showSidebar);
  }, [showSidebar]);

  return (
    <CustomBurgerIconWrapper
      className={`burger-icon ${isOpen ? "open" : ""}`}
      showSidebar={showSidebar}
      onClick={handleClick}
      id="nav-icon3"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </CustomBurgerIconWrapper>
  );
};
