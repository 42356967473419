import styled from "styled-components";
import { AquaStyledText } from "../home/products/AquaProducts/AquaProducts.styled";
import { Image } from "@chakra-ui/react";
import {
  BgWrapper,
  ChildMainWrapper,
  StyledText,
} from "../../common/styles/Common.styled";

export const AquaPageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    justify-content: center;
    align-items: center;
  }
`;

export const AquaChildMainWrapper = styled(ChildMainWrapper)`
  padding: 0px;
`;

export const AquaIntroContentWrapper = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const IntroWrapper = styled.div`
  /* min-height: calc(100vh - 70px); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const AquaBgWrapper = styled(BgWrapper)`
  height: 100vh;
  top: 0;
`;

export const IntroContentWrapper = styled.div`
  position: relative;
  top: -7%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

export const AquaPageStyledTitle = styled.h2`
  font-size: 32px;
  background-color: inherit;
  font-family: "Noto Serif", serif;
  text-align: center;
  padding-bottom: 20px;
  @media (max-width: 1000px) {
    font-size: 28px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

export const AquaIntroImage = styled(Image)`
  place-self: center;
  height: 70%;
  width: 70%;
  @media (max-width: 1000px) {
    height: 85%;
    width: 85%;
  }
  @media (max-width: 600px) {
    height: 100%;
    width: 100%;
  }
`;

export const AquaPageStyledText = styled(AquaStyledText)`
  width: 70%;
  padding: 30px 0;
  display: flex;
  align-self: center;
  text-align: center;
  @media (max-width: 1000px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const BottlesSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 100px;
  position: relative;
  background-color: var(--bg-secondary-color) !important;
  padding: 40px 40px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

export const VerticalLine = styled.div`
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #323232;
  left: 50%;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const HorizLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #323232;
  top: 47%;
  @media (max-width: 800px) {
    top: 49%;
  }
`;
export const HorizLine2 = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #323232;
  bottom: 0%;
`;

export const BottlesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--bg-secondary-color) !important;
`;

export const BoxesTitle = styled.h2`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  font-family: "Noto Serif", serif;
  background-color: var(--bg-secondary-color) !important;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

export const BoxesText = styled.p`
  width: 70%;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  background-color: var(--bg-secondary-color) !important;
  @media (max-width: 1000px) {
    width: 100%;
    font-size: 12px;
  }
`;

/*--------------------------------------------*/
export const IceMainWrapper = styled.div`
  /* margin: 30px 0px; */
  padding: 40px 40px;
`;

export const IceTitle = styled(BoxesTitle)`
  background-color: transparent !important;
  @media (max-width: 800px) {
    /* margin-top: 20px; */
    margin-bottom: 0px;
  }
`;

export const IceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px 40px; */
  @media (max-width: 1000px) {
    flex-direction: column;
    /* padding: 20px 40px; */
  }
`;

export const IceContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const IceImage = styled(Image)`
  place-self: center;
  padding: 40px;
  height: 40%;
  width: 40%;
  @media (max-width: 1000px) {
    padding: 20px;
    height: 70%;
    width: 70%;
  }
  @media (max-width: 600px) {
    padding: 10px;
    height: 100%;
    width: 100%;
  }
`;

export const IceText = styled(StyledText)`
  width: 70%;
  text-align: center;
  margin: 0px 0px 20px;
  span {
    margin-top: 10px;
    margin-left: 20px;
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: 1000px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

export const IceExtraImage = styled(Image)`
  place-self: center;
  padding: 40px;
  height: 50%;
  width: 50%;
  @media (max-width: 1000px) {
    padding: 20px;
    height: 70%;
    width: 70%;
  }
  @media (max-width: 600px) {
    padding: 10px;
    padding-top: 20px;
    height: 100%;
    width: 100%;
  }
`;
