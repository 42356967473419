import React, { useState } from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  width: 272px;
  height: 346px;
  background-color: var(--bg-secondary-color) !important;
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  background-color: var(--bg-secondary-color) !important;
`;

const HoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  background-color: var(--bg-secondary-color) !important;
`;

const ImageWithHover = ({ src, hoverSrc }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ImageContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <MainImage src={src} style={{ opacity: isHovered ? 0 : 1 }} />
      <HoverImage src={hoverSrc} style={{ opacity: isHovered ? 1 : 0 }} />
    </ImageContainer>
  );
};

export default ImageWithHover;
