import { useNavigate } from "react-router-dom";
import { PrivacyPolicyData, PrivacyPolicyTitle } from "../utils/mockData";
import { useContext, useEffect } from "react";
import {
  ChildMainWrapper,
  MainWrapper,
  StyledAiOutlineArrowLeft,
  StyledHeading,
  StyledText,
  TextHeading,
} from "../common/styles/Common.styled";
import { TitleText } from "../components/privacyPolicy/PrivacyPolicy.styled";
import parse from "html-react-parser";
// import { useScrollToTop } from "../components/hooks/useScrollToTop";
import { useMediaQuery } from "@chakra-ui/react";
import { DrawerContext } from "../components/reactContext/DrawerContext";

export const PrivacyPolicyPage = () => {
  // useScrollToTop("privacy-policy", -130);

  useEffect(() => {
    document.title = "Elixis";
  });

  const navigate = useNavigate();

  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);

  return (
    <MainWrapper
      className="MainWrapper"
      id="privacy-policy"
      style={{
        top: `${isSmallerThan1000 ? "80px" : "100px"}`,
        marginBottom: `${isSmallerThan1000 ? "80px" : "100px"}`,
      }}
      isOpen={showSidebar}
    >
      <ChildMainWrapper>
        <StyledHeading>
          <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
          {PrivacyPolicyTitle.title}
        </StyledHeading>
        <TitleText>
          {PrivacyPolicyData.map(({ text, title }) => (
            <>
              {title && <TextHeading as="h1">{title}</TextHeading>}
              {text && <StyledText>{parse(text)}</StyledText>}
            </>
          ))}
        </TitleText>
      </ChildMainWrapper>
    </MainWrapper>
  );
};
