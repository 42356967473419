import { Link } from "react-router-dom";
import {
  TruffleContentWrapper,
  TruffleCustomButton,
  TruffleProductsWrapper,
  TruffleStyledHeading,
  TruffleStyledText,
} from "./TruffleProducts.styled";

export const TruffleProducts = () => {
  return (
    <TruffleProductsWrapper
      className="TruffleProductsWrapper"
      id="TruffleProducts"
    >
      <TruffleContentWrapper className="TruffleContentWrapper">
        <TruffleStyledHeading>Truffle</TruffleStyledHeading>
        <TruffleStyledText style={{ backgroundColor: "transparent" }}>
          <p>
            Unveil the magic of truffles with Truffle Delights, an enchanting
            collection that celebrates nature's most exquisite flavors. Delight
            your senses as you embark on a culinary journey infused with the
            captivating aromas and exceptional taste of truffles.
          </p>
          <p>
            From the earthy richness of black truffles to the delicate notes of
            white truffles, discover the essence of culinary excellence with
            Truffle Delights.
          </p>
        </TruffleStyledText>
        <Link to="/truffle-page">
          <TruffleCustomButton style={{ backgroundColor: "transparent" }}>
            Learn More
          </TruffleCustomButton>
        </Link>
      </TruffleContentWrapper>
    </TruffleProductsWrapper>
  );
};
