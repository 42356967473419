import { Formik, Form } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { useToast } from "@chakra-ui/toast";
import { MyCheckbox } from "./common/MyCheckbox";
import { MyInput } from "./common/MyInput";
import { MyTextarea } from "./common/MyTextArea";
import {
  MiniHeaderWrapper,
  MyContactButton,
  MyInputsWrapper,
  MyText,
  SuccessToast,
} from "./contactForm.styled";
import { useRef } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";

export const ContactForm = () => {
  const toast = useToast();
  const toastIdRef = useRef();

  const close = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };

  const addToast = (status) => {
    toastIdRef.current = toast({
      position: "bottom",
      duration: 8000,
      isClosable: true,
      render: () => (
        <SuccessToast status={status}>
          <MiniHeaderWrapper className="MiniHeaderWrapper">
            <IoMdCheckmarkCircleOutline />
            <h1>
              {status === "success"
                ? "Form submitted!"
                : "Error submitting form"}
            </h1>
          </MiniHeaderWrapper>
          <p>
            {status === "success"
              ? "Thank you for contacting us. We will get back to you soon."
              : "There was an error processing your request"}
          </p>
          <button onClick={() => close()}>x</button>
        </SuccessToast>
      ),
    });
  };

  const serviceID = process.env.REACT_APP_CONTACT_SERVICE_ID;
  const templateID = process.env.REACT_APP_CONTACT_TEMPLATE_ID;
  const publicKeyID = process.env.REACT_APP_CONTACT_PUBLIC_KEY_ID;

  return (
    <>
      <Formik
        initialValues={{
          firstAndLastName: "",
          email: "",
          message: "Hello ",
          acceptedTerms: false, // added for checkbox
        }}
        validationSchema={Yup.object({
          firstAndLastName: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          message: Yup.string()
            .min(2, "Must be 2 or more characters")
            .max(200, "Maximum number of 200 characters")
            .required("Required"),
          acceptedTerms: Yup.boolean()
            .required("Required")
            .oneOf([true], "You must accept the terms and conditions."),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await new Promise((r) => setTimeout(r, 2000));
          setSubmitting(false);

          emailjs.send(serviceID, templateID, values, publicKeyID).then(
            function (response) {
              // console.log("SUCCESS!", response.status, response.text);
              if (response) {
                addToast("success");
              }
            },
            function (error) {
              // console.log("FAILED...", error);
              if (error) {
                addToast("error");
              }
            }
          );
          resetForm();
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <MyInputsWrapper className="MyInputsWrapper">
              <MyInput
                label="Name and Surname"
                name="firstAndLastName"
                type="text"
                id="my-input"
                //   children={
                //     <IoMdContact style={{ width: "30px", height: "30px" }} />
                //   }
              />
              <MyInput
                label="Email Address"
                name="email"
                type="email"
                id="my-input"
                //   children={
                //     <FaPaperPlane style={{ width: "25px", height: "25px" }} />
                //   }
              />
              <MyTextarea
                label="Message"
                name="message"
                type="text"
                size="md"
                resize="none"
              />
              <MyText>
                We understand the importance of your personal data and its role
                in effective communication and support. By submitting this form,
                you allow our support team to collect and utilize your personal
                data. For detailed information on how we safeguard and handle
                your information, please review our{" "}
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: "underline", fontWeight: "500" }}
                >
                  Privacy Policy.
                </Link>
              </MyText>
              <MyCheckbox
                value="Checkbox"
                name="acceptedTerms"
                type="checkbox"
                style={{
                  height: "18px",
                  width: "18px",
                }}
              >
                I accept that Elixis collects my data through this contact form.
              </MyCheckbox>
              <MyContactButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </MyContactButton>
            </MyInputsWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};
