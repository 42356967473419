import styled from "styled-components";
import aquaBg from "../../../../images/Backgrounds/aquaProductsBgA.webp";
import { Button } from "@chakra-ui/react";
import { StyledText } from "../../../../common/styles/Common.styled";

export const AquaProductsWrapper = styled.section`
  min-height: calc(100vh - 70px);
  width: 100%;
  /* background-color: navy; */
  padding: 30px 100px;

  scroll-margin-top: 70px;
  -webkit-scroll-margin-top: 70px;
  -moz-scroll-margin-top: 70px;
  -ms-scroll-margin-top: 70px;
  @media (max-width: 1000px) {
    min-height: calc(100vh - 60px);
    padding: 30px 50px;

    scroll-margin-top: 60px;
    -webkit-scroll-margin-top: 60px;
    -moz-scroll-margin-top: 60px;
    -ms-scroll-margin-top: 60px;
  }
  @media (max-width: 600px) {
    padding: 30px 30px;
  }
  position: relative;
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(${aquaBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 19% 50%;
    min-height: calc(100vh - 70px);
    width: 100%;
    z-index: 999;
    @media (max-width: 1000px) {
      min-height: calc(100vh - 60px);
    }
  }
`;

export const AquaContentWrapper = styled.div`
  width: 37%;
  position: absolute;
  right: 15%;
  top: 22%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: start;
  z-index: 9999;
  &.AquaContentWrapper p {
    background-color: transparent !important;
  }
  @media (max-width: 1000px) {
    width: 50%;
    top: 16%;
    right: 5%;
  }
`;

export const AquaStyledText = styled(StyledText)`
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const AquaCustomButton = styled(Button)`
  background-color: transparent;
  align-self: start;
  margin-top: 30px;
  box-shadow: 1px 1px 4px black;
  border-radius: 3px !important;
  font-weight: 500 !important;

  border: 2px solid var(--main-color) !important;
  color: var(--main-color) !important;
  z-index: 999999999;
  background-color: transparent !important;
  @media (max-width: 600px) {
    /* width: 70%; */
    /* padding: 15px 20px !important; */
    /* flex-wrap: wrap; */
    white-space: normal;
    word-wrap: break-word;
  }
`;
