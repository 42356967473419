import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { FormControl, FormErrorMessage, useToast } from "@chakra-ui/react";
import {
  CheckboxWrapper,
  InputButtonWrapper,
  MiniHeaderWrapper,
  MyCheckbox,
  MyInput,
  SubmitButton,
  SuccessToast,
} from "./NewsletterForm.styled";
import * as Yup from "yup";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";

const SubscriptionForm = () => {
  const toast = useToast();
  const toastIdRef = useRef();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    agreed: Yup.boolean().oneOf([true], "You must agree to the terms"),
  });

  const [isFocused, setIsFocused] = useState(false);

  const listID = process.env.REACT_APP_MAILCHIMP_LIST_ID;

  const [status, setStatus] = useState("default");

  const onHandleSubmit = (subscribe, values) => {
    subscribe({ EMAIL: values.email });
  };

  useEffect(() => {
    const close = () => {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
    };
    const addToast = (status) => {
      toastIdRef.current = toast({
        position: "bottom",
        duration: 6000,
        isClosable: true,
        render: () => (
          <SuccessToast status={status}>
            <MiniHeaderWrapper className="MiniHeaderWrapper">
              {status === "success" ? (
                <IoMdCheckmarkCircleOutline />
              ) : (
                <IoMdCloseCircleOutline />
              )}

              <h1>
                {status === "success"
                  ? "Subscription Successful!"
                  : "Subscription Failed"}
              </h1>
            </MiniHeaderWrapper>
            <p>
              {status === "success"
                ? "Thank you for subscribing to our newsletter!"
                : "There was an error while subscribing to our newsletter. Please try again later."}
            </p>
            <button onClick={() => close()}>x</button>
          </SuccessToast>
        ),
      });
    };
    if (status === "success") {
      addToast("success");
    } else if (status === "error") {
      addToast("error");
    }
    return () => setStatus("default");
  }, [status, toast]);

  return (
    <MailchimpSubscribe
      url={listID}
      render={({ subscribe, status, message }) => (
        <Formik
          onSubmit={(values) => onHandleSubmit(subscribe, values)}
          validationSchema={validationSchema}
          initialValues={{ email: "", agreed: false }}
        >
          <Form style={{ backgroundColor: "inherit" }}>
            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <InputButtonWrapper isFocused={isFocused}>
                    <MyInput
                      {...field}
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      className="NewsletterFormInput"
                    />
                    <SubmitButton
                      type="submit"
                      colorScheme="dark"
                      disabled={status === "sending" || status === "error"}
                    >
                      {status === "sending"
                        ? "Subscribing..."
                        : status === "error"
                        ? `Error: ${message ?? "Something went wrong"}`
                        : status === "success"
                        ? "Success"
                        : "Subscribe"}
                      {setStatus(status)}
                    </SubmitButton>
                  </InputButtonWrapper>
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="agreed">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.agreed && form.touched.agreed}
                >
                  <CheckboxWrapper className="CheckboxWrapper">
                    <MyCheckbox
                      className="MyCheckbox"
                      {...field}
                      id="agreed"
                      name="agreed"
                      style={{ backgroundColor: "inherit" }}
                    >
                      I agree to my e-mail being stored and used to receive the
                      newsletter
                    </MyCheckbox>
                  </CheckboxWrapper>
                  <FormErrorMessage>{form.errors.agreed}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Form>
        </Formik>
      )}
    />
  );
};

export default SubscriptionForm;
