import React, { createContext, useState } from "react";

export const DrawerContext = createContext();

export const DrawerContextProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <DrawerContext.Provider value={{ showSidebar, toggleSidebar }}>
      {children}
    </DrawerContext.Provider>
  );
};
