import styled from "styled-components";
import { MainWrapper } from "../../common/styles/Common.styled";

export const AboutUsMainWrapper = styled(MainWrapper)`
  padding-bottom: 100px;
  min-height: 100vh;
`;

export const AboutUsWrapper = styled.section`
  width: 100%;
  min-height: 90vh;
  position: relative;
  filter: ${({ isOpen }) => (isOpen ? "blur(4px)" : "none")};
`;

export const MyMainWrapper = styled.div`
  position: relative;
  top: 99px;
  min-height: 100%;
  /* margin: 0 auto 150px; */
  width: 100%;
  background-color: transparent;
  overflow: hidden;
`;
