import aboutUsBg from "../images/Backgrounds/aboutUsBg1.webp";

// export const footerSections = [
//   {
//     title: "CONTACT US",
//     item1: "<p>sales@elixistrade.com</p>",
//     item2: "<p>98 CUTTERMILL ROAD, STE466,</br>GREAT NECK NY 11021</p>",
//     item3: "<p>+1 (239) 744-1371</p>",
//   },
//   {
//     title: "ELIXIS TRADE CORP",
//     item1: "<p>Aqua Prestige</p>",
//     item2: "<p>Fresh Truffle</p>",
//   },
//   {
//     title: "POLICIES",
//     item1: "<p>Privacy Policy</p>",
//     item2: "<p>About Us</p>",
//     item3: "<p>Contact</p>",
//   },
//   {
//     title: "CONNECT WITH US",
//     socials: true,
//   },
// ];
export const AboutUsBgData = {
  imgSrc: aboutUsBg,
  opacity: 0.3,
};

export const AboutUsTitle = {
  title: "About Us",
};
export const AboutUsData = [
  {
    text: "<p>Welcome to Elixis Trade Corp, the premier destination for Aqua Prestige, a premium water brand that surpasses all others in nourishing and hydrating your body. We are also proud providers of high-quality food and beverages that enhance your well-being. Our mission is to offer our valued customers the finest products at affordable prices, while ensuring their shopping experience is both convenient and enjoyable.</p>",
  },
  {
    text: "<p>At Elixis Trade Corp, our passion lies in the meticulous curation of our product selection. Whether it's everyday essentials or luxury items, we strive to meet the diverse needs and preferences of our esteemed customers. With a keen eye for quality, reliability, and value, our team of experts handpicks each item we offer.</p>",
  },
  {
    text: "<p>Excellence is ingrained in every facet of our business. We take great pride in our top-notch customer service, consistently going above and beyond to exceed expectations. By fostering trust, reliability, and mutual respect, we aim to establish enduring relationships with our valued customers.</p>",
  },
  {
    text: "<p>As a socially responsible company, we believe in giving back to the community. A portion of our profits is dedicated to supporting local charities and non-profit organizations that align with our core values. We take pride in making a positive difference in the world.</p>",
  },
  {
    text: "<p>Thank you for choosing Elixis Trade Corp as your trusted provider of high-quality food and beverages. We are committed to your utmost satisfaction and eagerly look forward to serving you for many years to come.</p>",
  },
]; //Contact Us;

export const ContactUsTitle = {
  title: "Contact Us",
};

export const PrivacyPolicyTitle = {
  title: "Privacy Policy",
};

export const PrivacyPolicyData = [
  {
    text: "<p>At Elixis Trade Corp, we are committed to safeguarding the privacy and security of our valued customers. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you interact with our website and services. By accessing or using our platform, you agree to the terms outlined in this policy.</p>",
  },
  {
    title: "1. Information We Collect",
    text: "<p>We may collect the following types of information when you engage with our website: </br></br>• Name and surname: We collect your name and surname to personalize our communication and provide a more tailored experience.</br>• Email address: We collect your email address to send important updates, respond to your inquiries, and provide customer support.</br>• Message content: If you send us a message through our website, we collect the content of your message to understand your needs and provide appropriate assistance.</p>",
  },
  {
    title: "2. How We Use Your Information",
    text: "<p>We utilize the information collected to:</br></br>• Provide and personalize our services to meet your needs and preferences.</br>• Respond to your inquiries, requests, and provide customer support.</br>• Communicate important updates, promotional offers, and relevant information related to our products and services.</p>",
  },
  {
    title: "3. Information Protection and Retention",
    text: "<p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We retain your information only for as long as necessary to fulfill the purposes outlined in this policy unless a longer retention period is required or permitted by law.</p>",
  },
  {
    title: "4. Information Disclosure",
    text: "<p>We do not sell, trade, or rent your personal information to third parties. However, we may disclose your information in the following circumstances:</br></br>• Service providers: We may engage trusted third-party service providers to assist us in delivering our services, such as email marketing platforms or customer support tools. These providers are bound by strict confidentiality agreements and are only authorized to use your information for the specified purposes.</p>",
  },
  {
    title: "5. Your Rights and Choices",
    text: "<p>You have the right to access, update, and correct your personal information. If you wish to exercise these rights or have any questions or concerns regarding the handling of your information, please contact us using the contact details provided at the end of this policy.</p>",
  },
  {
    title: "6. Changes to the Privacy Policy",
    text: "<p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We encourage you to review this policy periodically for any updates. Your continued use of our website after any modifications constitutes your acceptance of the revised Privacy Policy.</p>",
  },
  {
    title: "7. Contact Us",
    text: "<p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at support@elixistrade.com.</p>",
  },
  {
    text: "<p>By using our website and services, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your personal information as outlined herein.</p>",
  },
]; //Privacy Policy;
