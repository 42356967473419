import styled from "styled-components";

export const AppWrapper = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: var(--bg-color);
`;
export const AppChildrenWrapper = styled.div`
  /* padding: 30px 100px 30px;

  @media (max-width: 1000px) {
    padding: 15px 50px 15px;
  } */
`;
