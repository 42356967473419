// import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { MainWrapper } from "../common/styles/Common.styled";
// import parse from "html-react-parser";
import { useMediaQuery } from "@chakra-ui/react";
import { DrawerContext } from "../components/reactContext/DrawerContext";
import {
  AquaBgWrapper,
  AquaChildMainWrapper,
  AquaIntroContentWrapper,
  AquaIntroImage,
  AquaPageContentWrapper,
  AquaPageStyledText,
  AquaPageStyledTitle,
  BottlesSection,
  BottlesSectionWrapper,
  BoxesText,
  BoxesTitle,
  HorizLine,
  HorizLine2,
  IceContentWrapper,
  IceExtraImage,
  IceImage,
  IceMainWrapper,
  IceText,
  IceTitle,
  IceWrapper,
  IntroContentWrapper,
  IntroWrapper,
  VerticalLine,
} from "../components/aquaPage/AquaPage.styled";
import ImageWithHover from "../components/imageWithHover/ImageWithHover";
import boxThree from "../images/AquaPage/boxThreeNew.webp";
import boxThreeWhite from "../images/AquaPage/boxThreeWhiteNew.webp";
import boxThreeBlack from "../images/AquaPage/boxThreeBlackNew.webp";
import boxSix from "../images/AquaPage/boxSixNew.webp";
import boxSixWhite from "../images/AquaPage/boxSixWhiteNew.webp";
import boxSixBlack from "../images/AquaPage/boxSixBlackNew.webp";
import iceCubes from "../images/AquaPage/iceCubesNew.webp";
import aquaIntroBg from "../images/AquaPage/aquaIntroBg.webp";
import iceBlocksCubes2 from "../images/AquaPage/iceBlocksCubes2.webp";
import introBg from "../images/AquaPage/introBg2.webp";

export const AquaPage = () => {
  useEffect(() => {
    document.title = "Elixis";
  });

  //   const navigate = useNavigate();

  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);

  return (
    <>
      <IntroWrapper className="IntroWrapper">
        <AquaBgWrapper
          className="AquaBgWrapper"
          imgSrc={introBg}
          opacity="0.5"
        />
        <IntroContentWrapper className="IntroContentWrapper">
          <AquaPageStyledTitle>
            {/* <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} /> */}
            AQUA PRESTIGE
          </AquaPageStyledTitle>
          <AquaPageStyledText className="AquaPageStyledText">
            Indulge in the epitome of refined hydration with Aqua Prestige, the
            pinnacle of premium water. Immerse yourself in a world where purity
            meets sophistication, and each sip elevates your refreshment
            experience to new heights. Crafted with meticulous attention to
            detail, Aqua Prestige offers a transformative journey that goes
            beyond mere drinking.
          </AquaPageStyledText>
        </IntroContentWrapper>
      </IntroWrapper>

      <MainWrapper
        className="MainWrapper"
        id="aqua-page"
        // style={{ top: `${isSmallerThan1000 ? "90px" : "120px"}` }}
        isOpen={showSidebar}
      >
        <AquaChildMainWrapper>
          <AquaPageContentWrapper>
            <AquaIntroContentWrapper>
              <AquaIntroImage src={aquaIntroBg} />
              <AquaPageStyledText>
                When you savor Aqua Prestige, you enter a realm of pure
                indulgence and sensory delight. With each sip, a symphony of
                sensations unfolds, caressing your palate with its velvety
                texture and subtle nuances. The captivating experience
                transcends taste alone, as Aqua Prestige awakens your senses and
                transports you to a state of blissful harmony.
                <br />
                <br />
                Join us on this journey of refined hydration as we invite you to
                explore the Aqua Prestige product line. Discover the harmonious
                union of taste, elegance, and wellness that awaits within each
                bottle.
              </AquaPageStyledText>
            </AquaIntroContentWrapper>

            <BottlesSectionWrapper className="BottlesSectionWrapper">
              <BottlesSection id="BottlesSection-1">
                <BoxesTitle>Set of 3 white bottles</BoxesTitle>
                <ImageWithHover src={boxThree} hoverSrc={boxThreeWhite} />
                <BoxesText>
                  This set contains a box with 3 White bottles of water with a
                  unique energetic value, the capacity of the bottles is 1L
                  each. Click/hover over the image for a more detailed look.
                </BoxesText>
              </BottlesSection>

              <BottlesSection id="BottlesSection-2">
                <BoxesTitle>Set of 3 black bottles</BoxesTitle>
                <ImageWithHover src={boxThree} hoverSrc={boxThreeBlack} />
                <BoxesText>
                  This set contains a box with 3 Black bottles of water with a
                  unique energetic value, the capacity of the bottles is 1L
                  each.
                </BoxesText>
              </BottlesSection>

              <BottlesSection id="BottlesSection-3">
                <BoxesTitle>Set of 6 white bottles</BoxesTitle>
                <ImageWithHover src={boxSix} hoverSrc={boxSixWhite} />
                <BoxesText>
                  This set contains a box with 6 White bottles of water with a
                  unique energetic value, the capacity of the bottles is 1L
                  each.
                </BoxesText>
              </BottlesSection>

              <BottlesSection id="BottlesSection-4">
                <BoxesTitle>Set of 6 black bottles</BoxesTitle>
                <ImageWithHover src={boxSix} hoverSrc={boxSixBlack} />
                <BoxesText>
                  This set contains a box with 6 Black bottles of water with a
                  unique energetic value, the capacity of the bottles is 1L
                  each.
                </BoxesText>
              </BottlesSection>
              <VerticalLine />
              <HorizLine />
              <HorizLine2 />
            </BottlesSectionWrapper>

            <IceMainWrapper className="IceMainWrapper">
              <IceTitle>Stainless Steel Ice</IceTitle>
              {isSmallerThan1000 ? (
                <IceWrapper className="IceWrapper">
                  <IceExtraImage src={iceBlocksCubes2} />
                  <IceContentWrapper>
                    <IceText>
                      <p>
                        To safeguard the distinctive qualities of Aqua Prestige
                        water, we offer a solution for those seeking cooler
                        refreshment without compromising its inherent purity.
                        Introducing our stainless steel ice cubes, designed to
                        maintain the integrity of our special water without
                        dilution.
                      </p>
                      <p>
                        Here's how to enjoy the optimal chilling experience:
                      </p>
                      <p>
                        1. Preparing the Stainless Steel Ice Cubes:
                        <br />
                        <span>
                          • Place the stainless steel ice cubes in your freezer
                          for a minimum of two hours to reach the desired chill.
                        </span>
                      </p>
                      <p>
                        2. Adding the Stainless Steel Ice Cubes to your Glass:
                        <br />
                        <span>
                          • With utmost care, gently drop the pre-chilled
                          stainless steel ice cubes into your glass.
                        </span>
                      </p>
                      <p>
                        3. Enhancing the Cooling Effect:
                        <br />
                        <span>
                          • Delight in the gradual cooling process by elegantly
                          swirling the liquid around the stainless steel ice
                          cubes within your glass.
                        </span>
                      </p>
                      <p>
                        With this meticulous approach, you can relish the
                        undiluted essence of Aqua Prestige water while enjoying
                        its perfectly chilled refreshment.
                      </p>
                    </IceText>
                    <IceImage src={iceCubes} />
                  </IceContentWrapper>
                </IceWrapper>
              ) : (
                <IceWrapper className="IceWrapper">
                  <IceContentWrapper>
                    <IceExtraImage src={iceBlocksCubes2} />
                    <IceImage src={iceCubes} />
                  </IceContentWrapper>

                  <IceText>
                    <p>
                      To safeguard the distinctive qualities of Aqua Prestige
                      water, we offer a solution for those seeking cooler
                      refreshment without compromising its inherent purity.
                      Introducing our stainless steel ice cubes, designed to
                      maintain the integrity of our special water without
                      dilution.
                    </p>
                    <p>Here's how to enjoy the optimal chilling experience:</p>
                    <p>
                      1. Preparing the Stainless Steel Ice Cubes:
                      <br />
                      <span>
                        • Place the stainless steel ice cubes in your freezer
                        for a minimum of two hours to reach the desired chill.
                      </span>
                    </p>
                    <p>
                      2. Adding the Stainless Steel Ice Cubes to your Glass:
                      <br />
                      <span>
                        • With utmost care, gently drop the pre-chilled
                        stainless steel ice cubes into your glass.
                      </span>
                    </p>
                    <p>
                      3. Enhancing the Cooling Effect:
                      <br />
                      <span>
                        • Delight in the gradual cooling process by elegantly
                        swirling the liquid around the stainless steel ice cubes
                        within your glass.
                      </span>
                    </p>
                    <p>
                      With this meticulous approach, you can relish the
                      undiluted essence of Aqua Prestige water while enjoying
                      its perfectly chilled refreshment.
                    </p>
                  </IceText>
                </IceWrapper>
              )}
            </IceMainWrapper>
          </AquaPageContentWrapper>
        </AquaChildMainWrapper>
      </MainWrapper>
    </>
  );
};
