import styled from "styled-components";

export const SocialsWrapper = styled.div`
  width: 100%;
  display: flex;
  > * + * {
    margin-left: 20px;
  }
  background-color: transparent !important;
  /* @media (max-width: 1001px) {
    > * + * {
      margin-left: 20px;
    }
  } */
`;

export const IconLink = styled.a`
  svg {
    height: 20px;
    width: 20px;
    background-color: inherit;
  }
  &:hover svg path {
    fill: var(--main-color);
  }

  path {
    fill: currentColor;
    transition: fill 0.4s ease-in-out;
  }

  &:hover path {
    fill: var(--main-color);
  }
`;
