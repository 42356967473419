// import { Image } from "@chakra-ui/react";
import {
  CustomButton,
  StyledHeading,
  StyledText,
} from "../../../common/styles/Common.styled";
import {
  LeftSideContent,
  MyImage,
  NewsContentWrapper,
  NewsWrapper,
  RightSideContant,
} from "./News.styled";
import fancyFoodNyc from "../../../images/News/fancyFoodNyc.webp";
import newsPic from "../../../images/News/newsPic1.webp";
import { Carousel } from "react-responsive-carousel";

export const News = () => {
  return (
    <NewsWrapper className="NewsWrapper" id="News">
      <StyledHeading style={{ display: "flex", alignSelf: "center" }}>
        News
      </StyledHeading>
      <Carousel
        autoPlay
        interval="4000"
        infiniteLoop
        showIndicators="false"
        showStatus="false"
        emulateTouch
        showThumbs={false}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={20}
        className="news-page-caro prevent-select"
      >
        <NewsContentWrapper className="NewsContentWrapper">
          <LeftSideContent className="LeftSideContent">
            <StyledText>
              “This week our team visited @fancyfoodshow in New York. <br />
              We had the honour to present #aquaprestige to the clients.
              <br />
              Thanks to the organizators for making this possible, it was truly
              an exceptional experience.”
              <br />
              <br />- B. Noveski, CEO at Elixis Trade Corp
            </StyledText>
            <a
              href="https://www.linkedin.com/feed/update/urn:li:activity:7079939246958026752/"
              style={{ display: "flex", alignSelf: "center" }}
            >
              <CustomButton variant="outline">View Post</CustomButton>
            </a>
          </LeftSideContent>
          <RightSideContant className="RightSideContant">
            <MyImage
              className="fancyFood"
              src={fancyFoodNyc}
              alt="Fancy Food NYX"
            />
          </RightSideContant>
        </NewsContentWrapper>

        <NewsContentWrapper className="NewsContentWrapper">
          <LeftSideContent className="LeftSideContent">
            <StyledText>
              “It was an honor to learn from Martin Riese, who is truly a
              pioneer in the industry.
              <br />
              Thank you, Martin Riese, for your time and expertise.
              <br />
              We are excited to continue promoting the benefits of quality water
              through our work at Elixis trade.”
              <br />
              <br />- B. Noveski, CEO at Elixis Trade Corp
            </StyledText>
            <a
              href="https://www.linkedin.com/feed/update/urn:li:activity:7057457149907546113/"
              style={{ display: "flex", alignSelf: "center" }}
            >
              <CustomButton variant="outline">View Post</CustomButton>
            </a>
          </LeftSideContent>
          <RightSideContant className="RightSideContant">
            <MyImage
              className="newsPic"
              src={newsPic}
              alt="Elixis's team with Martin Riese"
            />
          </RightSideContant>
        </NewsContentWrapper>
      </Carousel>
    </NewsWrapper>
  );
};
