import styled from "styled-components";
import truffleBg from "../../../../images/Backgrounds/truffleProductsBg3.webp";
//Bg3 is 50% quality, looks the same goes from 2.4mb to 780kb
import {
  StyledHeading,
  StyledText,
} from "../../../../common/styles/Common.styled";
import { Button } from "@chakra-ui/react";

export const TruffleProductsWrapper = styled.section`
  min-height: calc(100vh - 70px);
  width: 100%;
  /* background-color: crimson; */
  padding: 30px 100px;

  scroll-margin-top: 70px;
  -webkit-scroll-margin-top: 70px;
  -moz-scroll-margin-top: 70px;
  -ms-scroll-margin-top: 70px;
  @media (max-width: 1000px) {
    min-height: calc(100vh - 60px);
    padding: 30px 50px;

    scroll-margin-top: 60px;
    -webkit-scroll-margin-top: 60px;
    -moz-scroll-margin-top: 60px;
    -ms-scroll-margin-top: 60px;
  }
  @media (max-width: 600px) {
    padding: 30px 30px;
  }
  position: relative;
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(${truffleBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 27% 50%;
    height: 100%;
    width: 100%;
    z-index: 999;
    opacity: 0.4;
    @media (max-width: 600px) {
    }
  }
`;

export const TruffleStyledHeading = styled(StyledHeading)`
  @media (max-width: 1000px) {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    align-self: center;
  }
`;

export const TruffleContentWrapper = styled.div`
  width: 29%;
  position: absolute;
  /* right: 66%; */
  left: 10%;
  top: 20%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: start;
  z-index: 9999;
  &.TruffleContentWrapper p {
    background-color: transparent !important;
  }
  @media (max-width: 1000px) {
    width: 70%;
    top: 25%;
    left: 16%;
    text-align: center;
    align-self: center;
  }
`;

export const TruffleStyledText = styled(StyledText)`
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const TruffleCustomButton = styled(Button)`
  background-color: transparent;
  align-self: start;
  margin-top: 30px;
  box-shadow: 1px 1px 3px black;
  border-radius: 3px !important;
  font-weight: 500 !important;

  border: 2px solid var(--main-color) !important;
  color: var(--main-color) !important;
  z-index: 999999999;
  background-color: transparent !important;
  @media (max-width: 1000px) {
    align-self: center;
  }
  @media (max-width: 600px) {
    white-space: normal;
    word-wrap: break-word;
  }
`;
