import { ErrorMessage, useField } from "formik";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Textarea as ChakraTextArea,
} from "@chakra-ui/react";
import styled from "styled-components";

const StyledChakraTextArea = styled(ChakraTextArea)({
  // backgroundColor: "red!important",
  width: "100%",
  minHeight: "150px",
  // borderRadius: "4px",
  // border: "1px solid #868686",
});

export const MyTextarea = ({ name, label, ...props }) => {
  const [field] = useField(name);
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <StyledChakraTextArea {...props} {...field} />
      <FormHelperText color="red">
        <ErrorMessage name={name} />
      </FormHelperText>
    </FormControl>
  );
};
