import {
  BrochureContentWrapper,
  BrochureImg,
  BrochureWrapper,
  LeftSideContent,
  RightSideContant,
} from "./Brochure.styled";
import brochurePic from "../../../images/brochurePic.webp";

import {
  CustomButton,
  StyledHeading,
} from "../../../common/styles/Common.styled";
import myPdf from "../../../files/aqua-prestige.pdf";

export const Brochure = () => {
  return (
    <BrochureWrapper className="BrochureWrapper" id="Brochure">
      <BrochureContentWrapper className="BrochureContentWrapper">
        <LeftSideContent className="LeftSideContent">
          <a href={myPdf} target="_blank" rel="noreferrer noopener">
            <BrochureImg src={brochurePic} />
          </a>
        </LeftSideContent>
        <RightSideContant className="RightSideContant">
          <StyledHeading
            style={{
              fontStyle: "italic",
              fontSize: "28px",
              fontWeight: "200",
              textTransform: "capitalize",
              zIndex: "9999",
            }}
          >
            Luxury Meets Sophistication.
          </StyledHeading>
          <a href={myPdf} target="_blank" rel="noreferrer noopener">
            <CustomButton variant="outline">View Brochure</CustomButton>
          </a>
        </RightSideContant>
      </BrochureContentWrapper>
    </BrochureWrapper>
  );
};
