import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import homeBg1 from "../../../images/Backgrounds/homeBgBBB.webp";
import homeBg2 from "../../../images/Backgrounds/homeBg2AA.webp";
import homeBg3 from "../../../images/Backgrounds/homeBg3A.webp";
import {
  HomeButton,
  HomePageSliderImage,
  HomePageStyledIntro,
  HomePageStyledText,
  IntroHeadingTitle,
  SliderSectionWrapper,
} from "./HomeSlider.styled";
import { Link } from "react-router-dom";

const CustomCarousel = () => {
  return (
    <Carousel
      autoPlay
      interval="3500"
      infiniteLoop
      //   width="100%"
      showIndicators="false"
      showStatus="false"
      emulateTouch
      showThumbs={false}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={20}
      className="prevent-select"
    >
      <SliderSectionWrapper className="SliderSectionWrapper">
        <HomePageSliderImage
          className="HomePageSliderImage"
          src={homeBg1}
          alt="Slide 1"
          style={{ opacity: "0.35" }}
        />
        <HomePageStyledIntro className="HomePageStyledIntro" id="first-slide">
          <IntroHeadingTitle className="IntroHeadingTitle">
            Welcome to<span> Elixis Trade</span>
            <p>Where Pure Elegance Meets Unrivaled Refreshment.</p>
          </IntroHeadingTitle>
        </HomePageStyledIntro>
      </SliderSectionWrapper>

      <SliderSectionWrapper className="SliderSectionWrapper">
        <HomePageSliderImage
          src={homeBg2}
          alt="Slide 2"
          style={{ opacity: "0.4" }}
        />
        <HomePageStyledIntro className="HomePageStyledIntro">
          <IntroHeadingTitle className="IntroHeadingTitle">
            Immerse Yourself in <span>Pure Elegance</span>
            <br />
            and <span>Unrivaled Refreshment</span>
          </IntroHeadingTitle>
          <HomePageStyledText className="HomePageStyledText">
            Experience Aqua Prestige, our premium water that harmoniously
            balances purity and taste. Sourced from pristine springs and
            meticulously purified, Aqua Prestige offers a refreshing and
            invigorating hydration experience like no other.
          </HomePageStyledText>
          <Link to="/aqua-page">
            <HomeButton colorScheme="purple">Learn more</HomeButton>
          </Link>
        </HomePageStyledIntro>
      </SliderSectionWrapper>

      <SliderSectionWrapper className="SliderSectionWrapper">
        <HomePageSliderImage
          src={homeBg3}
          alt="Slide 3"
          style={{ opacity: "0.5" }}
        />
        <HomePageStyledIntro className="HomePageStyledIntro">
          <IntroHeadingTitle className="IntroHeadingTitle">
            Discover the Essence of
            <br />
            <span>Culinary Opulence</span>
          </IntroHeadingTitle>
          <HomePageStyledText className="HomePageStyledText">
            Delve into the world of truffles, where unparalleled flavor and
            luxury unite. Our handpicked selection of truffles, sourced from
            renowned regions worldwide, embodies the epitome of culinary
            excellence. Elevate your dishes with these rare and aromatic
            treasures.
          </HomePageStyledText>
          <Link to="/truffle-page">
            <HomeButton>Learn more</HomeButton>
          </Link>
        </HomePageStyledIntro>
      </SliderSectionWrapper>
    </Carousel>
  );
};

export default CustomCarousel;
