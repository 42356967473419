import { Accordion, Heading } from "@chakra-ui/react";
import styled from "styled-components";

export const MainFooterWrapper = styled.div`
  filter: ${({ isOpen }) => (isOpen ? "blur(4px)" : "none")};
`;

export const FooterWrapper = styled.div`
  min-height: 240px;
  /* position: fixed; */
  /* bottom: 0; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 30px 100px 30px;
  border-top: 1px solid var(--secondary-color);
  background-color: var(--bg-secondary-color);
  @media (max-width: 800px) {
    padding: 15px 50px 15px;
    /**add styles for mobile footer.... */
  }
`;

export const ColumnsWrapper = styled.div`
  position: relative;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    background-color: inherit;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ColumnsChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-bottom: 10px;
  }
  p {
    background-color: inherit;
    font-size: 12px;
  }
  @media (max-width: 1001px) {
    > * + * {
      margin-bottom: 0px;
    }
  }
`;

export const CreditsWrapper = styled.div`
  background-color: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.6875em;
  font-weight: 300;
  line-height: 1.7em;
  margin-bottom: 25px;
`;

export const TitleHeading = styled(Heading)`
  text-transform: uppercase;
  margin-bottom: 20px;
  background-color: inherit;
  font-size: 14px;
  color: var(--main-color);
  @media (max-width: 1001px) {
    margin-bottom: 0px;
  }
`;

export const Rights = styled.div`
  background-color: inherit;
`;

export const DevelopedBy = styled.a`
  cursor: pointer;
  font-weight: 300;
  transition: color 0.2s ease-in-out;
  :hover {
    color: var(--main-color);
  }
`;

/* MobileWrapper */
export const MyCustomAccordion = styled(Accordion)`
  @media (min-width: 1001px) {
    display: none;
  }
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: var(--bg-secondary-color) !important;
`;

/*For the footer lines.. */
// export const CustomHorizLine = styled.div`
//   width: 100%;
//   height: 2px;
//   background-color: red;
// `;
