import { useNavigate } from "react-router-dom";
import { AboutUsBgData, AboutUsData, AboutUsTitle } from "../utils/mockData";
import { useContext, useEffect } from "react";
import {
  BgWrapper,
  ChildMainWrapper,
  StyledAiOutlineArrowLeft,
  StyledHeading,
  StyledText,
  TextHeading,
} from "../common/styles/Common.styled";
import {
  TitleText,
  TitleTextChildWrapper,
} from "../components/privacyPolicy/PrivacyPolicy.styled";
import parse from "html-react-parser";
import {
  AboutUsMainWrapper,
  AboutUsWrapper,
} from "../components/aboutUs/AboutUs.styled";
import { useMediaQuery } from "@chakra-ui/react";
import { DrawerContext } from "../components/reactContext/DrawerContext";

export const AboutUsPage = () => {
  useEffect(() => {
    document.title = "Elixis";
  });

  const navigate = useNavigate();

  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);

  return (
    <AboutUsWrapper className="AboutUsPageWrapper" isOpen={showSidebar}>
      <BgWrapper
        className="AboutUsBgWrapper"
        imgSrc={AboutUsBgData.imgSrc}
        opacity={AboutUsBgData.opacity}
      />
      <AboutUsMainWrapper
        className="MainWrapper"
        id="about-us"
        style={{ top: `${isSmallerThan1000 ? "80px" : "100px"}` }}
      >
        <ChildMainWrapper className="ChildMainWrapper">
          <StyledHeading>
            <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
            {AboutUsTitle.title}
          </StyledHeading>
          <TitleText>
            {AboutUsData.map(({ text, title }) => (
              <TitleTextChildWrapper className="TitleTextChildWrapper">
                {title && <TextHeading as="h1">{title}</TextHeading>}
                {text && <StyledText>{parse(text)}</StyledText>}
              </TitleTextChildWrapper>
            ))}
          </TitleText>
        </ChildMainWrapper>
      </AboutUsMainWrapper>
    </AboutUsWrapper>
  );
};
