import styled from "styled-components";
import { AquaStyledText } from "../home/products/AquaProducts/AquaProducts.styled";
import { Image } from "@chakra-ui/react";
import { BgWrapper, StyledText } from "../../common/styles/Common.styled";

export const IntroWrapper = styled.div`
  /* min-height: calc(100vh - 70px); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const IntroContentWrapper = styled.div`
  position: relative;
  top: -7%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

export const TruffleBgWrapper = styled(BgWrapper)`
  height: 100vh;
  top: 0;
`;

export const TrufflePageStyledTitle = styled.h2`
  font-size: 32px;
  background-color: inherit;
  font-family: "Noto Serif", serif;
  text-align: center;
  padding-bottom: 20px;
  @media (max-width: 1000px) {
    font-size: 28px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

export const TrufflePageStyledText = styled(AquaStyledText)`
  width: 70%;
  padding: 30px 0;
  display: flex;
  align-self: center;
  text-align: center;
  font-size: 24px;
  font-weight: 400;

  span {
    color: var(--main-color);
    font-weight: 500;
    font-size: 28px;
  }
  @media (max-width: 1000px) {
    width: 80%;
    font-size: 20px;
    span {
      font-size: 24px;
    }
  }
  @media (max-width: 600px) {
    width: 90%;
    font-size: 16px;
    span {
      font-size: 20px;
    }
  }
`;

/*---------------------------------*/

export const TruffleContentWrapper = styled.div`
  padding: 60px 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;

    padding: 40px 40px;
  }
`;
export const MyTrufflePic = styled(Image)`
  height: 400px;
  width: 400px;
  border: 1px solid var(--main-color);
  @media (max-width: 600px) {
    height: 80%;
    width: 80%;
  }
`;
export const TitleTextLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 60px;
  @media (max-width: 1000px) {
    padding: 10px 0 40px;
  }
`;

export const TitleTextRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  @media (max-width: 1000px) {
    padding: 40px 0;
  }
`;

export const TruffleTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 40px;
  @media (max-width: 1000px) {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TruffleText = styled(StyledText)`
  @media (max-width: 1000px) {
    text-align: center;
  }
`;

export const HorizLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #323232;
  top: 50%;
  @media (max-width: 1000px) {
    top: 50%;
  }
`;
