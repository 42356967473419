import React from "react";
import { IconLink, SocialsWrapper } from "./Socials.styled";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";

const Socials = () => {
  return (
    <SocialsWrapper>
      <IconLink href="https://www.linkedin.com/company/elixis-trade-corp/">
        <BsLinkedin />
      </IconLink>
      <IconLink href="https://www.facebook.com/profile.php?id=100090871074005&mibextid=LQQJ4d">
        <BsFacebook />
      </IconLink>
      <IconLink href="https://www.instagram.com/elixistrade/?igshid=YmMyMTA2M2Y%3D">
        <BsInstagram />
      </IconLink>
    </SocialsWrapper>
  );
};

export default Socials;
