import { Checkbox } from "@chakra-ui/checkbox";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { ErrorMessage, useField } from "formik";
import styled from "styled-components";

const IAcceptText = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 600px) {
    font-size: 11px;
  }
`;

export const MyCheckbox = ({ children, label, name, ...props }) => {
  const [field] = useField(name);

  return (
    <FormControl>
      <FormLabel className="checkbox">
        <IAcceptText className="MyDivWrapper">
          <Checkbox
            {...field}
            {...props}
            type="checkbox"
            height="24px"
            width="24px"
          />
          {children}
        </IAcceptText>
        {label}
      </FormLabel>
      <FormHelperText color="red">
        <ErrorMessage name={name} />
      </FormHelperText>
    </FormControl>
  );
};
