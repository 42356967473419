import CustomCarousel from "./CustomCarousel";
import { HomeSliderWrapper } from "./HomeSlider.styled";

export const HomeSlider = () => {
  return (
    <HomeSliderWrapper className="HomeSliderWrapper" id="Home">
      <CustomCarousel />
    </HomeSliderWrapper>
  );
};
