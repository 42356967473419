import { useNavigate } from "react-router-dom";
import { ContactUsTitle } from "../utils/mockData";
import { useContext, useEffect } from "react";
import {
  ChildMainWrapper,
  StyledAiOutlineArrowLeft,
  StyledHeading,
} from "../common/styles/Common.styled";
import { useMediaQuery } from "@chakra-ui/react";
import {
  ContactInfoWrapper,
  ContactLeftSideWrapper,
  ContactMainWrapper,
  ContactRightSideWrapper,
  ContactWrapper,
} from "../components/contact/ContactPage.styled";
import Socials from "../components/socials/Socials.jsx";
import { ContactForm } from "../components/contact/contactForm/contactForm";
import { DrawerContext } from "../components/reactContext/DrawerContext";

export const ContactPage = () => {
  useEffect(() => {
    document.title = "Elixis";
  });

  const navigate = useNavigate();

  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);

  return (
    <ContactMainWrapper
      className="MainWrapper"
      id="contact-us"
      style={{ top: `${isSmallerThan1000 ? "80px" : "100px"}` }}
      isOpen={showSidebar}
    >
      <ChildMainWrapper>
        <StyledHeading>
          <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
          {ContactUsTitle.title}
        </StyledHeading>
        <ContactWrapper className="ContactWrapper">
          <ContactLeftSideWrapper className="ContactLeftSideWrapper">
            <ContactInfoWrapper className="ContactInfoWrapper">
              <li>
                <span>Phone:</span>
                <br />
                <a href="tel: +1 (239) 744-1371">+1 (239) 744-1371</a>
              </li>
              <li>
                <span>Email:</span>
                <br />
                <a href="mailto:sales@elixistrade.com">sales@elixistrade.com</a>
              </li>
              <li>
                <span>Adress:</span>
                <br />
                98 CUTTERMILL ROAD, STE466, GREAT NECK NY 11021
              </li>
              <li>MON-FRI 09:00 AM - 07:00 PM, SAT 10:00 AM - 02:00 PM</li>
            </ContactInfoWrapper>
            <Socials />
          </ContactLeftSideWrapper>
          <ContactRightSideWrapper className="ContactRightSideWrapper">
            <ContactForm />
          </ContactRightSideWrapper>
        </ContactWrapper>
      </ChildMainWrapper>
    </ContactMainWrapper>
  );
};
