import {
  MiniLogo,
  MiniText,
  NewsletterWrapper,
  Subtitle,
  Title,
} from "./Newsletter.styled";
import logoShort from "../../images/Logos/logoShortA.svg";
import SubscriptionForm from "./SubscriptionForm";

const Newsletter = () => {
  return (
    <NewsletterWrapper className="NewsletterWrapper">
      <MiniLogo src={logoShort}></MiniLogo>
      <Title>NEWSLETTER</Title>
      <Subtitle>STAY IN THE NOW</Subtitle>
      <SubscriptionForm className="SubscriptionForm" />
      <MiniText>
        Stay up-to-date with the latest news, promotions, and exclusive offers
        from Elixis Trade Corp by subscribing to our newsletter. Be the first to
        know about new product releases, special events, and more. Simply
        provide your email address below and click "SUBMIT" to join our mailing
        list.
      </MiniText>
    </NewsletterWrapper>
  );
};
export default Newsletter;
