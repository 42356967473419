import styled from "styled-components";

export const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-right: 30px; */
  background-color: transparent !important;
  p {
    margin-top: 20px;
    background-color: transparent !important;
  }
  @media (max-width: 800px) {
    /* margin-right: 15px; */
  }
`;

export const TitleTextChildWrapper = styled.div`
  background-color: transparent !important;
  div {
    background-color: transparent !important;
  }
`;

// export const ContentWrapper = styled.div`
//   height: calc(100vh - 260px);
//   overflow-y: auto;
// `;
