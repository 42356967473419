import { Image } from "@chakra-ui/react";
import styled from "styled-components";

export const NewsWrapper = styled.section`
  min-height: calc(100vh - 70px);
  /* min-height: 100%; */
  width: 100%;
  /* background-color: green; */
  padding: 30px 100px;

  scroll-margin-top: 70px;
  -webkit-scroll-margin-top: 70px;
  -moz-scroll-margin-top: 70px;
  -ms-scroll-margin-top: 70px;
  @media (max-width: 1000px) {
    min-height: calc(100vh - 60px);
    padding: 30px 50px;

    scroll-margin-top: 60px;
    -webkit-scroll-margin-top: 60px;
    -moz-scroll-margin-top: 60px;
    -ms-scroll-margin-top: 60px;
  }
  @media (max-width: 600px) {
    padding: 30px 30px;
  }

  /* Custom styles */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * + * {
    margin-top: 20px;
  }
`;

export const NewsContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 40px;
  > * + * {
    margin-left: 30px;
  }
  padding: 20px;
  @media (max-width: 1000px) {
    flex-direction: column;
    > * + * {
      margin-left: 0px;
      margin-top: 40px;
    }
  }
  /* justify-content: start; */
`;

export const LeftSideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  @media (max-width: 1000px) {
    margin-top: 20px;
    text-align: center;
    align-self: center;
  }
  .newsPic {
    max-width: 393px;
    max-height: 524px;
  }
  .fancyFood {
    max-width: 393px;
    max-height: 524px;
  }
`;

export const RightSideContant = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  .newsPic {
    max-width: 393px;
    max-height: 524px;
  }
  .fancyFood {
    max-width: 393px;
    max-height: 524px;
  }
  @media (max-width: 1000px) {
    align-self: center;
  }
  @media (max-width: 600px) {
  }
`;

export const MyImage = styled(Image)`
  padding: 7px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
`;
