import { useContext, useEffect } from "react";
import { MainWrapper } from "../common/styles/Common.styled";
import { useMediaQuery } from "@chakra-ui/react";
import { DrawerContext } from "../components/reactContext/DrawerContext";
import introBg from "../images/TrufflePage/truffleBg.png";
import {} from "../components/aquaPage/AquaPage.styled";
import {
  HorizLine,
  IntroContentWrapper,
  IntroWrapper,
  MyTrufflePic,
  TitleTextLeftWrapper,
  TitleTextRightWrapper,
  TruffleBgWrapper,
  TruffleContentWrapper,
  TrufflePageStyledText,
  TrufflePageStyledTitle,
  TruffleText,
  TruffleTitle,
} from "../components/trufflePage/TrufflePage.styled";
import freshBlackTruffle from "../images/TrufflePage/freshBlackTruffle.png";
import freshWhiteTruffle from "../images/TrufflePage/freshWhiteTruffle.png";

export const TrufflePage = () => {
  useEffect(() => {
    document.title = "Elixis";
  });
  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const { showSidebar } = useContext(DrawerContext);

  return (
    <>
      <IntroWrapper className="IntroWrapper">
        <TruffleBgWrapper
          className="TruffleBgWrapper"
          imgSrc={introBg}
          opacity="0.5"
        />
        <IntroContentWrapper className="IntroContentWrapper">
          <TrufflePageStyledTitle>Truffle</TrufflePageStyledTitle>
          <TrufflePageStyledText className="TrufflePageStyledText">
            <span>Immerse in Truffle Opulence</span>Discover Our Exquisite
            Collection
          </TrufflePageStyledText>
        </IntroContentWrapper>
      </IntroWrapper>

      <MainWrapper className="MainWrapper" id="aqua-page" isOpen={showSidebar}>
        {isSmallerThan1000 ? (
          <TruffleContentWrapper>
            <TitleTextLeftWrapper>
              <TruffleTitle>Fresh Black Truffle</TruffleTitle>
              <TruffleText>
                Also referred to as the Summer Truffle, our Fresh Black Truffle
                is a culinary gem available year-round. Renowned for its
                delicate scent and captivating flavor profile, this esteemed
                variety adds a touch of elegance to every dish it graces.
                <br />
                <br />
                With its exquisite aroma and delectable taste, our Fresh Black
                Truffle is a perfect choice for elevating pasta dishes and
                creating flavorful "fritaja" masterpieces.
                <br />
                <br />
                To ensure accessibility and exceptional quality, we proudly
                offer frozen black truffles that are available throughout the
                year. Experience the culinary allure of our Fresh Black Truffle
                and unlock a world of gourmet possibilities.
              </TruffleText>
            </TitleTextLeftWrapper>
            <MyTrufflePic src={freshBlackTruffle} />
          </TruffleContentWrapper>
        ) : (
          <TruffleContentWrapper>
            <MyTrufflePic src={freshBlackTruffle} />
            <TitleTextLeftWrapper>
              <TruffleTitle>Fresh Black Truffle</TruffleTitle>
              <TruffleText>
                Also referred to as the Summer Truffle, our Fresh Black Truffle
                is a culinary gem available year-round. Renowned for its
                delicate scent and captivating flavor profile, this esteemed
                variety adds a touch of elegance to every dish it graces.
                <br />
                <br />
                With its exquisite aroma and delectable taste, our Fresh Black
                Truffle is a perfect choice for elevating pasta dishes and
                creating flavorful "fritaja" masterpieces.
                <br />
                <br />
                To ensure accessibility and exceptional quality, we proudly
                offer frozen black truffles that are available throughout the
                year. Experience the culinary allure of our Fresh Black Truffle
                and unlock a world of gourmet possibilities.
              </TruffleText>
            </TitleTextLeftWrapper>
          </TruffleContentWrapper>
        )}

        <HorizLine />

        <TruffleContentWrapper>
          <TitleTextRightWrapper>
            <TruffleTitle>Fresh White Truffle</TruffleTitle>
            <TruffleText>
              Also referred to as the Summer Truffle, our Fresh Black Truffle is
              a culinary gem available year-round. Renowned for its delicate
              scent and captivating flavor profile, this esteemed variety adds a
              touch of elegance to every dish it graces. <br />
              <br />
              With its exquisite aroma and delectable taste, our Fresh Black
              Truffle is a perfect choice for elevating pasta dishes and
              creating flavorful "fritaja" masterpieces.
              <br />
              <br />
              To ensure accessibility and exceptional quality, we proudly offer
              frozen black truffles that are available throughout the year.
              Experience the culinary allure of our Fresh Black Truffle and
              unlock a world of gourmet possibilities.
            </TruffleText>
          </TitleTextRightWrapper>
          <MyTrufflePic src={freshWhiteTruffle} />
        </TruffleContentWrapper>
      </MainWrapper>
    </>
  );
};
