import styled from "styled-components";
import { MainWrapper } from "../../common/styles/Common.styled";

export const ContactMainWrapper = styled(MainWrapper)`
  padding-bottom: 100px;
  min-height: 100vh;
`;

export const ContactWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 40px 0px;
  > * + * {
    margin-left: 40px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > * + * {
      margin-left: 0px;
      margin-top: 50px;
    }
  }
`;

export const ContactLeftSideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  > * + * {
    margin-top: 40px;
  }
`;

export const ContactInfoWrapper = styled.ul`
  width: 100%;
  font-size: 14px;
  > * + * {
    margin-top: 20px;
  }
  span {
    margin-top: 10px !important;
    color: var(--main-color);
  }
`;

export const ContactRightSideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
