import styled from "styled-components";

export const NewsletterWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 30px 100px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary-color);
  /* > * + * {
    margin-bottom: 20px;
  } */
  @media (max-width: 800px) {
    padding: 15px 50px 15px;
    /**add styles for mobile footer.... */
  }
`;

export const MiniLogo = styled.img`
  background-color: inherit;
`;

export const Title = styled.h1`
  font-size: 20px;
  background-color: inherit;
  margin-top: 20px;
  /* margin-bottom: 10px; */
  opacity: 80%;
  font-weight: 200;
  @media (max-width: 1000px) {
    font-size: 16px;
    margin-top: 15px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
    margin-top: 10px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 40px;
  background-color: inherit;
  font-family: "Noto Serif", serif;
  margin-bottom: 40px;
  @media (max-width: 1000px) {
    font-size: 32px;
    margin-bottom: 35px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
    margin-bottom: 30px;
  }
`;

export const MiniText = styled.p`
  text-align: center;
  background-color: inherit;
  font-size: 14px;
  width: 60%;
  margin-bottom: 40px;
  @media (max-width: 1000px) {
    width: 80%;
    font-size: 13px;
    margin-bottom: 35px;
  }
  @media (max-width: 600px) {
    width: 100%;
    font-size: 12px;
    margin-bottom: 30px;
  }
`;
