import { ErrorMessage, useField } from "formik";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  // InputLeftAddon,
} from "@chakra-ui/react";
import styled from "styled-components";

const StyledChakraInput = styled(ChakraInput)({
  // backgroundColor: "red!important",
  // width: "100%",
  // height: "35px",
  // borderRadius: "4px!important",
  // border: "1px solid #868686",
});

export const MyInput = ({
  name,
  label,
  helperText,
  errorMsg,
  children,
  ...props
}) => {
  const [field] = useField(name);

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        {/* <InputLeftAddon bgColor="#fff" children={children} /> */}
        <StyledChakraInput {...props} {...field} />
      </InputGroup>
      <FormHelperText color="red">
        <ErrorMessage name={name} />
      </FormHelperText>
    </FormControl>
  );
};
