import styled from "styled-components";
import { Button, Image } from "@chakra-ui/react";

export const HomeSliderWrapper = styled.section`
  /* min-height: calc(100vh - 99px); */
  height: 100vh;
  width: 100%;
  /* background-color: OliveDrab; */
  /* padding: 30px 100px; */

  /* scroll-margin-top: 70px !important; */
  /* scroll-margin-top: 70px; */
  /* -webkit-scroll-margin-top: 70px;
  -moz-scroll-margin-top: 70px;
  -ms-scroll-margin-top: 70px; */

  /* margin-top: 99px; */
  @media (max-width: 1000px) {
    /* min-height: calc(100vh - 77px); */
    /* padding: 30px 50px; */

    /* scroll-margin-top: 60px; */
    /* scroll-margin-top: 60px; */
    /* -webkit-scroll-margin-top: 60px;
    -moz-scroll-margin-top: 60px;
    -ms-scroll-margin-top: 60px; */

    /* margin-top: 77px; */
  }
  @media (max-width: 600px) {
    /* padding: 30px 30px; */
  }
`;
/*-----------------------------------------*/

export const SliderSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* padding: 30px 100px; */
  /* margin-top: 99px; */
  @media (max-width: 1000px) {
    /* margin-top: 77px; */
    /* padding: 30px 50px; */
  }
  @media (max-width: 600px) {
    /* padding: 30px 30px; */
  }
`;

export const HomePageSliderImage = styled(Image)`
  object-fit: cover;
  height: 100vh;
  :first-child {
    object-position: 50% 0%;
  }
  @media (max-width: 600px) {
    object-position: 73% 50%;
  }
`;

export const HomeContentWrapper = styled.div`
  /* position: absolute;
background-color: transparent;
display: flex;
flex-direction: column
justify-content: center;
align-items: center; */
`;

export const HomePageStyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30%;
  font-size: 32px;
  text-transform: capitalize;
  font-weight: 300;
  background-color: transparent;
  text-shadow: 2px 2px 8px black;
  padding: 0px 30px;

  span {
    color: var(--main-color);
    background-color: transparent;
    font-weight: 500;
    font-family: "Noto Serif", serif;
  }
  p {
    font-size: 26px !important;
  }
  @media (max-width: 1000px) {
    top: 30%;
    font-size: 26px;
    padding: 0px 20px;
    p {
      background-color: transparent !important;
      font-size: 22px !important;
      margin-top: 8px !important;
    }
  }
  @media (max-width: 600px) {
    font-size: 22px;
    padding: 0px 10px;
    p {
      font-size: 18px !important;
    }
  }
`;

export const IntroHeadingTitle = styled.div`
  font-family: "Noto Serif", serif;
`;

export const HomePageStyledText = styled.div`
  width: 60%;
  margin-top: 30px !important;
  align-self: center;
  background-color: transparent;
  font-size: 18px !important;
  text-transform: none;
  @media (max-width: 1000px) {
    font-size: 15px !important;
    width: 70%;
  }
  @media (max-width: 600px) {
    font-size: 12px !important;
  }
`;

export const HomeButton = styled(Button)`
  background-color: transparent;
  align-self: center;
  margin-top: 30px;
  box-shadow: 1px 1px 4px black;
  border-radius: 5px !important;
  font-weight: 500 !important;

  border: 2px solid var(--main-color) !important;
  color: var(--main-color) !important;
  z-index: 999999999;
  background-color: transparent !important;
`;
