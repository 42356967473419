import React, { useContext } from "react";
import Newsletter from "../newsletter/Newsletter";
import CustomFooter from "./CustomFooter";
import { MainFooterWrapper } from "./Footer.styled";
import { DrawerContext } from "../reactContext/DrawerContext";

const Footer = () => {
  const { showSidebar } = useContext(DrawerContext);

  return (
    <MainFooterWrapper className="MainFooterWrapper" $isOpen={showSidebar}>
      <Newsletter />
      <CustomFooter />
    </MainFooterWrapper>
  );
};

export default Footer;
