import { Button, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import styled from "styled-components";

export const ContactFormWrapper = styled(motion.div)`
  position: relative;
  top: 99px;
  min-height: 100%;
  margin: 0 auto;
  width: 70%;

  @media (max-width: 1100px) {
    width: 80%;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`; //className="MainWrapper"

export const ChildContactFormWrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  @media (max-width: 1000px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

export const ContactMe = styled.p`
  font-size: 14px;
  opacity: 50%;
  @media (max-width: 1000px) {
    font-size: 10px;
  }
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-left: 38px;
`;

export const StyledHeading = styled.h1`
  font-size: 36px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    font-size: 32px;
  }
`;

export const SuccessToast = styled.div`
  position: relative;
  padding: 10px;
  background-color: ${({ status }) => (status === "success" ? "green" : "red")};
  border-radius: 5px;
  h1,
  p {
    background-color: inherit;
  }
  button {
    position: absolute;
    top: 5px;
    right: 10px;
  }
`;

export const MiniHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: inherit;
  svg {
    margin-right: 10px;
    background-color: inherit;
    height: 20px;
    width: 20px;
  }
`;

export const MyInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 25px;
  }
  /* font-size: 14px; */
  @media (max-width: 800px) {
    > * + * {
      margin-top: 15px;
    }
    /* font-size: 12px; */
  }
`;

export const MyText = styled(Text)`
  font-size: 11px;
  @media (max-width: 600px) {
    font-size: 8px;
  }
`;

export const MyContactButton = styled(Button)`
  height: 35px;
  width: 100%;
  background-color: red;
  border: 1px solid var(--main-color);
`;
