import { Box, Button, Heading } from "@chakra-ui/react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import styled from "styled-components";

export const StyledAiOutlineArrowLeft = styled(AiOutlineArrowLeft)({
  marginRight: "15px",
  width: "25px",
  height: "25px",
  cursor: "pointer",
  backgroundColor: "transparent",
});

export const MainWrapper = styled.div`
  position: relative;
  min-height: 100%;
  /* padding-bottom: 100px; */
  filter: ${({ isOpen }) => (isOpen ? "blur(4px)" : "none")};
  background-color: transparent;
  overflow: hidden;
`; //className="MainWrapper"

export const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  /* top: 0;
  left: 0; */
  background: ${(props) => ` url(${props.imgSrc})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  opacity: ${(props) => props.opacity};
`;
/*------------------------------------------------------------------------------------*/
export const StyledHeading = styled.h1`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  @media (max-width: 1300px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`; /* example: <- About Us */

export const TextHeading = styled(Heading)`
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.2em;
`; /* example: the sub-title inside every page with a Styled Heading... */

export const StyledText = styled(Box)`
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7em;
  display: flex;
  flex-direction: column;
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 1000px) {
    font-size: 13px;
    p {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  @media (max-width: 600px) {
    font-size: 12px;
    p {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`; /* example: the text inside every page with a Styled Heading... */
/*------------------------------------------------------------------------------------*/

export const ChildMainWrapper = styled.div`
  background-color: transparent;
  padding: 30px 100px;
  @media (max-width: 1000px) {
    padding: 15px 50px;
  }
  @media (max-width: 600px) {
    padding: 15px 30px;
  }
`;

export const CustomButton = styled(Button)`
  /* width: 30%; */
  border: 1px solid var(--main-color) !important;
  color: var(--main-color) !important;
  margin-top: 40px;
  z-index: 9999;
`;
